/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from '@emotion/styled'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Parallax } from "react-spring/renderprops-addons.cjs"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import SVG from "@lekoarts/gatsby-theme-cara/src/components/svg"
import { UpDown, UpDownWide } from "@lekoarts/gatsby-theme-cara/src/styles/animations"
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import React, { useState, useEffect }  from "react"
import { window, exists } from "browser-monads"
import { navigate } from 'gatsby-link'
import axios from "axios"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import {useStaticQuery, graphql} from "gatsby"
import Sidebar from "@lekoarts/gatsby-theme-cara/src/components/sidebar"
import SEO from "@lekoarts/gatsby-theme-cara/src/components/seo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowboarding, faUsers, faAddressBook, faPhoneSquareAlt, faEnvelopeOpenText, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons'
import Sitemap from "@lekoarts/gatsby-theme-cara/src/components/sitemap"



const Input = styled.input` 
  background: #242b3c;
    border: 0px;
    font-size: 1em;
    padding:10px;
    margin:5px;
    color:white;
    width:100%;
`

const SubmitButton = styled.input`
  border: 0px;
    padding: 10px;
    background: #3caf7b;
    color: white;
    font-size: 1em;
    font-weight: 800;
`   

const LongArrowLeft = styled.div`
  display: block;
  margin: 30px auto;
  width: 25px;
  height: 25px;
  border-top: 2px solid gray;
  border-left: 2px solid gray ;
  transform: rotate(-45deg);
     &:after{
    content: "";
    display: block;
    width: 2px;
    height: 45px;
    background-color: gray;
    transform: rotate(-45deg) translate(15px, 4px);
    left: 0;
    top: 0;
  }
`

const ImageWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  align-items:center;
  align-content:space-around;
  flex-direction:row;
  background: linear-gradient(45deg, #ffffff, #ffffff);
  padding:10px;
  border-radius:3px;
  margin-top:40px;
`



const classroom  = () => {

useEffect(() => {
        window.scrollTo(0,0)
    })

const data = useStaticQuery(graphql`
query {
  ttmlogo: file(relativePath: {eq: "ttm-horiz.png"}) {
    childImageSharp {
      fluid(maxWidth:170){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  advansr1: file(relativePath: {eq: "advansr/advansr11.jpg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  advansr2: file(relativePath: {eq: "advansr/advansr12.jpg"}) {
    childImageSharp {
      fluid(maxWidth:900){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image1: file(relativePath: {eq: "advansr/advansr.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image2: file(relativePath: {eq: "advansr/apple.png"}) {
    childImageSharp {
      fluid(maxWidth:130){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image3: file(relativePath: {eq: "advansr/googleclass.png"}) {
    childImageSharp {
      fluid(maxWidth:150){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  },
  image4: file(relativePath: {eq: "advansr/googledev.png"}) {
    childImageSharp {
      fluid(maxWidth:180){
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize
      
      }
    }
  }
}`
)  


        return (
        
  <div id="outer-container">
  <SEO/>
    <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
        <div id="page-wrap">
       
        
        <div sx={{mt:60,p:20,maxWidth:900,m:'0 auto'}}>
        <a href="/">
          <Img sx={{marginTop:'20px'}}
            fluid={data.ttmlogo.childImageSharp.fluid}
            alt="Touch This Media Logo"
          />   
        </a>
        <h1 sx={{
            color: "heading",
            m: 1,
            mt:80,
            mb:40
        
          }}>GOOGLE CLASSROOM REWARDS </h1>

       

      
           <Img sx={{m:'0 auto'}}
            fluid={data.advansr1.childImageSharp.fluid}
            alt="Students using Advansr software on mobile devices"
            style={{ width:`100%`}} 
          />
       
        <div>
        <h2>Solution</h2>
            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            advanSR (pronounced advancer) is a fun, engaging, & rewarding tool that helps students and their parents to enhance productive scholastic behaviors.   </p>
            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            advanSR focuses on improving three areas of educational life:  </p>
            <ul>
            <li sx={{
      listStyleType:`none`,        
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>  <FontAwesomeIcon icon={faUsers}/> Attendance</li> 
            <li sx={{
      listStyleType:`none`,
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>  <FontAwesomeIcon icon={faSnowboarding}/> Extra-Curricular Activities </li>
            <li sx={{
      listStyleType:`none`,
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}> <FontAwesomeIcon icon={faChalkboardTeacher}/> Parental Engagement.</li>
            </ul>
            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            Our latest release now works with Google Classroom, so students can be rewarded based on class grades & participation. 
            </p>

            <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
            Available on App Store & Google Play Store. Solutions start at $2,500 with a $10 per student fee.
            </p>

            
           <Img
            fluid={data.advansr2.childImageSharp.fluid}
            alt="Students using Advansr software on mobile devices"
            style={{ width:`100%`}} 
            />

      </div>

      <div>
        <h2>Useful to</h2>
        <p sx={{
      fontSize: [1, 2],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`}}>
      Parents, teachers, students, school districts, home Schools
        </p>

      </div>

       <div id="ttm-form">
                <h2>Get Connected</h2>
                
               <a href="https://advansr.online">advanSR.online</a>
       </div>

        <ImageWrapper>
           <Img
            fluid={data.image1.childImageSharp.fluid}
            alt="AdvanSR logo"
            style={{ width:`100%`}} 
          />

          <Img
            fluid={data.image2.childImageSharp.fluid}
            alt="Apple App Store Logo" 
            style={{ width:`100%`}}
          />

          <Img
            fluid={data.image3.childImageSharp.fluid}
            alt="Google Classroom logo" 
            style={{ width:`100%`}}
          />
          <Img
            fluid={data.image4.childImageSharp.fluid}
            alt="Google Developer logo" 
            style={{ width:`100%`}}
          />
        </ImageWrapper>
        <Sitemap/>
        <div sx={{ m:"0 auto",mt:50,
        width:'100%',alignItems:`center`, textAlign: `center`}}>
         <h4>Touch This Media </h4> 
        <p> <FontAwesomeIcon icon={faAddressBook}/> 3288 Adams Ave <br/>
          P.O. Box 16113 <br/>
          San Diego, CA 92176
        </p>
        <p> <FontAwesomeIcon icon={faPhoneSquareAlt}/> <a href="tel:619-796-2675"> 619-796-2675</a> </p>
        <button sx={{variant: `buttons.toggle`, fontWeight: `semibold`, display: `block`, mx: `auto`}} type="button"> <FontAwesomeIcon icon={faEnvelopeOpenText}/> <a sx={{color:`background`}}href="mailto:sales@touchthismedia.com"> Email Us </a> </button>
        </div>

    </div>
    </div>
    </div>
  )    
    

}



export default classroom 




